import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

if (location && location.search && location.search.length > 0) {
  const split = location.search.substring(1).split('&');
  const items: {key: string, value: string}[] = [];
  let debug = false;
  for (const item of split) {
    const keyValue = item.split('=');
    if (keyValue.length === 2) {
      const key = keyValue[0];
      const value = keyValue[1];
      if (key === 'debug') {
        debug = Boolean(value);
      } else {
        items.push({ key, value });
      }
      items.push({ key, value });
    }
  }
  if (!debug) {
    localStorage.clear();
  }
  for (const item of items) {
    localStorage.setItem(item.key, item.value);
  }
} else {
  // reload
  const previousMode = localStorage.getItem('debug');
  let debug = previousMode === 'true' || false;
  if (!debug) {
    localStorage.clear();
  }
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
bootstrap().catch((err) => console.log(err));
