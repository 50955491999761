/* tslint:disable:no-string-literal */
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AppGuard implements CanActivate {

    constructor(
        public toastController: ToastController,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const component = route.component;
        if (component && component.hasOwnProperty('getName') && typeof component['getName'] === 'function') {
            const componentName = component['getName']();
            return from(this.getPermission(componentName));
        }
        return true;
    }

    async getPermission(componentName: string): Promise<boolean> {
        const canAccess = await this.canAccess(componentName);
        if (!canAccess) {
            this.presentMissingPermission();
        }
        return canAccess;
    }

    async canAccess(componentName: string): Promise<boolean> {
        return true;
    }

    async presentMissingPermission() {
        const toast = await this.toastController.create({
            message: 'Sie haben nicht die nötigen Zugriffsrechte.',
            duration: 2000,
            color: 'danger',
            position: 'top'
        });
        toast.present();
    }
}
