import { Injectable } from '@angular/core';
import {PlatformService} from "./platform.service";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private platformService: PlatformService) { }

  public getEvaluationQuestionListView(): boolean {
    const value = this.get('evaluation-question-list-view');
    return value ? value === 'true' : this.platformService.isBrowser();
  }

  public setEvaluationQuestionListView(value: boolean) {
   this.set('evaluation-question-list-view', String(value));
  }

  public getView(): string {
    return this.get('View'); // 'Evaluation' or 'Result' or 'Evaluator' or 'Evaluated'
  }

  public getUrl(): string {
    let api = this.get('api') || 'localhost:3000';
    api = api.replace(/[-]+/g, '.');
    const protocol = this.get('protocol') || 'http';
    return protocol + '://' + api;
  }

  public getApiKey(): string {
    return this.get('xapikey');
  }

  public getCompanyId(): string {
    return this.get('companyid');
  }

  public getWorkOrderId(): string {
    return this.get('WorkOrder');
  }

  public getUserId(): string {
    return this.get('User');
  }

  public isDebugMode(): boolean {
    const value = this.get('debug');
    return value ? Boolean(value) : false;
  }

  private set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  private get(key: string): string {
    return localStorage.getItem(key);
  }

  private remove(key: string) {
    localStorage.removeItem(key);
  }

  private clear() {
    localStorage.clear();
  }
}
