import { Injectable } from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  public static readonly DEFAULT_OPTIONS = {
    duration: 2500,
    position: 'top',
    cssClass: 'custom-toast-css',
  };

  constructor(
    private toastController: ToastController
  ) {
  }

  async presentSuccess(message: string, options?: any) {
    return this.present(message, 'success', options);
  }

  async presentWarning(message: string, options?: any) {
    return this.present(message, 'warning', options);
  }

  async presentInfo(message: string, options?: any) {
    return this.present(message, 'info', options);
  }

  async presentError(message: string, options?: any) {
    return this.present(message, 'danger', options);
  }

  public async present(msg: any, color: string, options: any = {}) {
    if (!msg) {
      return;
    }
    let message = msg;
    if (typeof msg === 'object') {
      if (msg.message) {
        message = msg.message;
      } else if (msg.error || msg.Error || msg.Err) {
        message = msg.error || msg.Error || msg.Err;
      } else {
        message = JSON.stringify(msg);
      }
    }
    if (typeof message === 'string' && message.length <= 2) {
      return;
    }
    const toastOptions = Object.assign(Object.assign({}, ToastService.DEFAULT_OPTIONS), {message, color}, options);
    const toast = await this.toastController.create(toastOptions);
    await toast.present();
  }
}
