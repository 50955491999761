import { Injectable } from '@angular/core';
import {StorageService} from './storage.service';
import {HttpClient} from '@angular/common/http';
import {ToastService} from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private cachedDebugFlag: boolean;

  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private toastService: ToastService
  ) {

  }

  async initDebugMode() {
    this.cachedDebugFlag = await this.get('evaluations/debug');
  }

  public debug() {
    return this.cachedDebugFlag;
  }

  get Api() {
    return this.storage.getUrl() + '/';
  }

  public async get(Path: string, Params: {} = {}, disableWarning = true, SuccessMessage: string = ''): Promise<any> {
    try {
      const response = await this.http.get(this.Api + Path, { params: Params, observe: 'response' } ).toPromise();
      await this.toastService.presentSuccess(SuccessMessage);
      return response.body;
    } catch (e) {
      if (!disableWarning) {
        this.toastService.presentWarning(e);
      }
      return null;
    }
  }

  public async post(Path: string, Object: any, Params: {} = {}, SuccessMessage: string = '', ShowErrorMessage: boolean = true): Promise<any> {
    try {
      const responseObject = await this.http.post(this.Api + Path, Object, { params: Params }).toPromise();
      await this.toastService.presentSuccess(SuccessMessage);
      return responseObject;
    } catch (e) {
      if (ShowErrorMessage) {
        await this.toastService.presentError(e.error || 'Es wurden nicht genügend Angaben gemacht');
      }
      return null;
    }
  }
}
