import {Component} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Router} from '@angular/router';
import {ApiService} from './services/api.service';
import {PlatformService} from "./services/platform.service";
import {StorageService} from "./services/storage.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  showSettings = false;
  showConfiguration = false;

  constructor(
    private platform: Platform,
    private router: Router,
    private api: ApiService,
    private platformService: PlatformService,
    private storage: StorageService,
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.platform.ready();
    await this.platformService.init();
    return this.redirect();
  }

  async redirect() {
    await this.api.initDebugMode();
    const view = this.storage.getView();
    const workOrderId = this.storage.getWorkOrderId();
    const userId = this.storage.getUserId();
    if (view === 'User-List') {
      return this.router.navigate(['/user-list']);
    } else if (view === 'Evaluation' && workOrderId) {
      return this.router.navigate(['/evaluation']);
    } else if (view === 'Result' && !workOrderId) {
      return this.router.navigate(['/result-overview']);
    } else if (view === 'Result' && workOrderId) {
      return this.router.navigate(['/evaluated-work-order']);
    } else if (view === 'Evaluator' && userId) {
      return this.router.navigate(['/evaluation-result', 'evaluator', userId]);
    } else if (view === 'Evaluated' && userId) {
      return this.router.navigate(['/evaluation-result', 'evaluated', userId]);
    } else {
      return this.router.navigateByUrl('/home');
    }
  }
}
