import { Injectable } from '@angular/core';
import {Platform} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  platformName: string;
  constructor(
    private platform: Platform
  ) {}

  async init() {
    await this.platform.ready();
    this.platformName = this.getPlatformName();
  }

  getPlatformName() {
    let platformName = 'web';
    if (this.platform.is('ios')) {
      platformName = 'ios';
    } else if (this.platform.is('android')) {
      platformName = 'android';
    }
    return platformName;
  }

  public isIos(): boolean {
    return this.platformName === 'ios' || this.platformName === 'ipad' || this.platformName == 'iphone';
  }

  public isAndroid(): boolean {
    return this.platformName === 'android';
  }

  public isApp(): boolean {
    return !this.isBrowser();
  }

  public isBrowser(): boolean {
    return this.platformName === 'web';
  }

  public getName(): string {
    return this.platformName;
  }
}
