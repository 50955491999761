import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'result',
    loadChildren: () => import('./pages/result/result.module').then( m => m.ResultPageModule)
  },
  {
    path: 'question',
    loadChildren: () => import('./pages/question/question.module').then( m => m.QuestionPageModule)
  },
  {
    path: 'evaluation',
    loadChildren: () => import('./pages/evaluation/evaluation.module').then( m => m.EvaluationPageModule)
  },
  {
    path: 'result-overview',
    loadChildren: () => import('./pages/result-overview/result-overview.module').then( m => m.ResultOverviewPageModule)
  },
  {
    path: 'evaluators',
    loadChildren: () => import('./pages/evaluators/evaluators.module').then( m => m.EvaluatorsPageModule)
  },
  {
    path: 'evaluated',
    loadChildren: () => import('./pages/evaluated/evaluated.module').then( m => m.EvaluatedPageModule)
  },
  {
    path: 'evaluation-result',
    loadChildren: () => import('./pages/evaluation-result/evaluation-result.module').then( m => m.EvaluationResultPageModule)
  },
  {
    path: 'comments',
    loadChildren: () => import('./modals/comments/comments.module').then( m => m.CommentsPageModule)
  },
  {
    path: 'evaluated-work-order',
    loadChildren: () => import('./pages/evaluated-work-order/evaluated-work-order.module').then( m => m.EvaluatedWorkOrderPageModule)
  },
  {
    path: 'evaluation-question',
    loadChildren: () => import('./pages/evaluation-question/evaluation-question.module').then( m => m.EvaluationQuestionPageModule)
  },
  {
    path: 'user-list',
    loadChildren: () => import('./pages/user-list/user-list.module').then( m => m.UserListPageModule)
  },

];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
